.sub_account_users_count {
	display: flex;
	gap: 16px;
	align-items: center;
	&__view_btn {
		background: #3c65d60f;
		border-radius: 8px;
		padding: 4px 12px;
		color: #0051cc;
		font-size: 12px;
		font-weight: 500;
		cursor: pointer;
	}
}

.sub_account-user-modal {
	max-width: 100vw !important;
	height: 100%;
	.Modal__wrapper {
		width: 100vw !important;
		min-height: calc(100vh - 40px);
		display: flex;
		gap: 8px;
		flex-direction: column;
	}
}

.subaccount-joined {
	background: #389f7533;
	color: #389f75;
	font-weight: 500;
	font-size: 12px;
	padding: 2px 8px;
	border-radius: 8px;
	width: fit-content;
}
.subaccount-pending {
	background: #e89f0d33;
	color: #b45c04;
	font-weight: 500;
	font-size: 12px;
	padding: 2px 8px;
	border-radius: 8px;
	width: fit-content;
}

.sub-account-user-subscription {
	border: 2px solid hsla(324, 90%, 62%, 0.5);
	color: #f545ae;
	background-color: hsla(324, 90%, 62%, 0.1);
	border-radius: 16px;
	padding: 8px;
	width: fit-content;
	&__value {
		text-align: center;
		font-weight: 500;
		font-size: 12px;
	}
}
.sub-account-table-wrapper {
	margin-top: 16px;
}
